import React from 'react';
import { H1 } from '../style/atoms/Headings';
import { ThemeProvider } from 'styled-components';
import { theme } from '../style/theme';

const About = () => (
  <ThemeProvider theme={theme}>
  <div style={{ color: `teal` }}>
    <H1>No about page yet.</H1>
  </div>
  </ThemeProvider>
);

export default About;
